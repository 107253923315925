<script setup lang="ts">
const { t } = useI18n()
const { isEmpty, totalItems } = await useCart()
const { icons } = useDesign()

const emit = defineEmits(['load-cart', 'load-checkout'])

defineOptions({
  name: 'CartActions',
})
</script>

<template>
  <div
    class="bg-body bleed-bg bleed-body md:bleed-none relative sticky bottom-0 z-10 w-full transition-transform duration-300 md:block md:h-full md:translate-y-0"
    :class="{
      'hidden translate-y-full': isEmpty,
      'block translate-y-0': !isEmpty,
    }"
  >
    <div class="py-4 md:sticky md:top-20 md:space-y-4">
      <CartOverview class="hidden md:block" v-if="!isEmpty" />
      <CartEmpty class="hidden md:flex" v-else />

      <div class="grid grid-cols-[auto,minmax(0,1fr)] gap-2 md:grid-cols-1">
        <button
          :title="t('btn.cart')"
          type="button"
          :disabled="isEmpty"
          class="hover:bg-primary-dark-2/5 dark:hover:bg-primary-light-2/5 border-neutral-dark-1 dark:border-neutral-light-1 dark:text-light text-dark relative flex h-[42px] w-[42px] items-center justify-center rounded-full border transition-colors duration-200 md:w-full [&:disabled]:pointer-events-none [&:disabled]:opacity-30"
          @click="emit('load-cart')"
        >
          <span
            v-if="totalItems > 0"
            class="bg-primary text-dark absolute -right-1 -top-1 flex h-5 w-5 items-center justify-center rounded-full text-xs md:hidden"
            v-text="totalItems"
          />
          <Icon
            :name="icons.CART_OUTLINE"
            class="size-6 fill-current md:hidden"
          />
          <span class="hidden md:block" v-text="t('btn.cart')" />
        </button>

        <KButton
          :text="t('btn.checkout')"
          class="w-full"
          :disabled="isEmpty"
          @click="emit('load-checkout')"
        />
      </div>
    </div>
  </div>
</template>

<i18n>
en:
  btn:
    checkout: "Checkout"
    cart: "Cart"
de:
  btn:
    checkout: "Zur Kasse"
    cart: "Warenkorb"
es:
  btn:
    checkout: "Pagar"
    cart: "Carrito"
</i18n>
